<template>
  <section>
    <div v-if="!isLoading">
      <v-card class="rounded-lg">
        <v-card-text class="pa-0">
          <v-row no-gutters>
            <v-col sm="4" class="py-0" v-if="!shrink">
              <v-carousel hide-delimiters :cycle="true" :show-arrows="false" style="height: 100%; width: 100%;" class="hidden-xs-only">
                <v-carousel-item v-for="(image, index) in course.images" :key="index" style="height: 100%; width: 100%;">
                  <v-img :src="image.url" style="height: 100%; width: 100%;" class="rounded-bl-lg rounded-tl-lg"></v-img>
                </v-carousel-item>
              </v-carousel>
              <v-carousel hide-delimiters :cycle="true" :show-arrows="false" style="height: 80px; width: 100%;" class="hidden-sm-and-up">
                <v-carousel-item v-for="(image, index) in course.images" :key="index" style="height: 100%; width: 100%;">
                  <v-img :src="image.url" style="height: 100%; width: 100%;" class="rounded-tl-lg rounded-tr-lg"></v-img>
                </v-carousel-item>
              </v-carousel>
            </v-col>
            <v-col sm="12" class="py-0" v-else>
              <v-carousel hide-delimiters :cycle="true" :show-arrows="false" style="height: 80px; width: 100%;">
                <v-carousel-item v-for="(image, index) in course.images" :key="index" style="height: 100%; width: 100%;">
                  <v-img :src="image.url" style="height: 100%; width: 100%;" class="rounded-tl-lg rounded-tr-lg"></v-img>
                </v-carousel-item>
              </v-carousel>
            </v-col>
            <v-col class="pa-4" style="position: relative;">
              <div :class="shrink ? 'friendly-2' : 'friendly-3'">
                <v-avatar :size="shrink ? 32 : 46" class="ma-1">
                  <v-img contain :src="course.organization.logoUrl"></v-img>
                </v-avatar>
                {{ course.name }}
              </div>
              <div class="mt-2" v-if="!shrink">
                <p v-snip="{ lines: 4, mode: 'js', midWord: false }">
                  {{ course.subtitle }}
                </p>
              </div>
              <div>
                <div class="pa-4">
                  <v-row v-if="!shrink">
                    <v-col align="right">
                      <div v-if="products.length >= 1">
                        <div class="mb-4">
                          <v-row>
                            <v-col align="left" class="hidden-xs-only">
                              <span class="friendly-2 font-weight-black">Admission</span>
                            </v-col>
                            <v-col align="right">
                              <span class="friendly-2 font-weight-black">${{ products[0].cents / 100 }}<span class="caption"> x {{ products.length }}</span></span>
                            </v-col>
                          </v-row>
                        </div>
                      </div>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col align="right">
                      <span class="friendly-2 font-weight-black">Total ${{ ((products[0].cents * products.length) / 100).toFixed(2) }}</span>
                    </v-col>
                  </v-row>
                  <v-row class="hidden-xs-only" v-if="!shrink">
                    <v-col xl="2" lg="2" md="2" sm="2">
                      <v-btn block @click="doRemove" color="error" class="py-5">
                        <font-awesome-icon :icon="['fas', 'trash']"/>
                      </v-btn>
                    </v-col>
                    <v-col offset-xl="6" xl="4" offset-lg="4" lg="6" md="10" sm="10">
                      <v-row align="right" no-gutters>
                        <v-col cols="auto">
                          <v-btn color="primary" :disabled="products.length === 1" @click="doSubtractProduct" class="rounded-tr-0 rounded-br-0 py-5">
                            <font-awesome-icon :icon="['fas', 'minus']"/>
                          </v-btn>
                        </v-col>
                        <v-col>
                          <v-text-field
                            type="number"
                            :value="products.length"
                            outlined
                            hide-details
                            dense
                            class="text-center rounded-0"
                            @input="doSetProductQuantity"
                            :min="1"
                            :max="12"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="auto">
                          <v-btn color="primary" :disabled="products.length >= 12" @click="doAddProduct" class="rounded-tl-0 rounded-bl-0 py-5">
                            <font-awesome-icon :icon="['fas', 'plus']"/>
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                  <div class="hidden-sm-and-up mt-4">
                    <div>
                      <v-row align="right" no-gutters>
                        <v-col cols="2" class="mr-2">
                          <v-btn block @click="doRemove" color="error" class="py-5">
                            <font-awesome-icon :icon="['fas', 'trash']"/>
                          </v-btn>
                        </v-col>
                        <v-col cols="auto">
                          <v-btn color="primary" :disabled="products.length === 1" @click="doSubtractProduct" class="rounded-tr-0 rounded-br-0 py-5">
                            <font-awesome-icon :icon="['fas', 'minus']"/>
                          </v-btn>
                        </v-col>
                        <v-col>
                          <v-text-field
                            type="number"
                            :value="products.length"
                            outlined
                            hide-details
                            dense
                            class="text-center rounded-0"
                            @input="doSetProductQuantity"
                            :min="1"
                            :max="12"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="auto">
                          <v-btn color="primary" :disabled="products.length >= 12" @click="doAddProduct" class="rounded-tl-0 rounded-bl-0 py-5">
                            <font-awesome-icon :icon="['fas', 'plus']"/>
                          </v-btn>
                        </v-col>
                      </v-row>
                    </div>
                  </div>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </div>
  </section>
</template>

<script>
export default {
  name: 'course-cart-item',
  props: {
    courseId: {
      type: Number,
      default: 0
    },
    products: {
      type: Array,
      default: () => {
        return []
      }
    },
    uuid: {
      type: String,
      default: String()
    },
    shrink: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    isLoading: true,
    course: {

    }
  }),
  methods: {
    doLoad () {
      this.isLoading = true

      this.$services.course.doRead(this.courseId).then(course => {
        if (course === null) {
          this.doRemove()
          this.$root.$emit('showError', 'The course added to your cart is no longer available.')
        } else {
          if (!course.isActive) {
            this.doRemove()
            this.$root.$emit('showWarning', `The ${course.name} course is no longer available for purchase currently `)
          }

          this.course = course
        }
      }).catch(error => {
        this.doRemove()
        this.$root.$emit('showError', error.message)
      }).finally(_ => {
        this.isLoading = false
      })
    },
    doAddProduct () {
      const products = this.products
      products.push(this.course)

      this.$store.dispatch('doSetProductsInProductGroup', {
        uuid: this.uuid,
        products: products
      })
    },
    doSetProductQuantity (quantity) {
      if (quantity >= 12) {
        quantity = 12
      }

      const products = []

      for (let i = 0; i < quantity; i++) {
        products.push(this.course)
      }

      this.$store.dispatch('doSetProductsInProductGroup', {
        uuid: this.uuid,
        products: products
      })
    },
    doSubtractProduct () {
      const products = this.products
      products.pop()

      this.$store.dispatch('doSetProductsInProductGroup', {
        uuid: this.uuid,
        products: products
      })
    },
    doRemove () {
      this.$store.dispatch('doRemoveProductGroup', this.uuid)
    }
  },
  mounted () {
    this.doLoad()
  }
}
</script>

<style scoped>
::v-deep .v-carousel__item {
  height: 100% !important;
  width: 100% !important;
}
</style>
