<template>
  <div>
    <div v-if="isLoading.paymentIntents" class="text-center">
      <v-progress-circular
        indeterminate
        color="primary"
      ></v-progress-circular>
    </div>
    <div v-else>
      <v-expansion-panels accordion v-model="currentPanel">
        <v-expansion-panel>
          <v-expansion-panel-header>
            Contact Information
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <div>
              <v-form ref="contactInformationForm">
                <v-text-field v-model="contactInformation.firstName" label="First Name" outlined :rules="inputs.rules.simplyRequired"></v-text-field>
                <v-text-field v-model="contactInformation.lastName" label="Last Name" outlined :rules="inputs.rules.simplyRequired"></v-text-field>
                <v-text-field v-model="contactInformation.email" label="Email" outlined :rules="inputs.rules.emailRequired"></v-text-field>
                <v-text-field v-model="contactInformation.phoneNumber" label="Mobile Number" outlined :rules="inputs.rules.phoneNumberRequired" v-mask="['(###) ###-####']"></v-text-field>
                <v-btn color="primary" @click="doOpenBilling" style="float: right;" :disabled="!contactInformationFilled">Next</v-btn>
              </v-form>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel :disabled="!contactInformationFilled">
          <v-expansion-panel-header>
            Billing Address
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <address-element @complete="doSaveAddress"></address-element>
            <div style="float: right;" class="mt-4">
              <v-btn color="primary" @click="doOpenPayment" :disabled="!billingAddressFilled">Next</v-btn>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel :disabled="!billingAddressFilled">
          <v-expansion-panel-header>
            Payment
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <stripe-element-payment
              v-if="stripe.checkoutPayment.ready"
              ref="stripePayment"
              class="elevation-0 rounded-lg pa-2 stripePaymentElement"
              :pk="stripeApiAccount.pk"
              :elements-options="stripe.checkoutPayment.elementOptions"
              :elements-style="stripe.checkoutPayment.elementStyle"
              :confirm-params="stripe.checkoutPayment.confirmParams"
              @loading="transactionLoading"
            />

            <div class="pa-2">
              <v-btn color="primary" block @click="doProcessTransaction" :loading="isLoading.stripePayment">Pay now</v-btn>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
  </div>
</template>

<script>
import { StripeElementPayment } from '@vue-stripe/vue-stripe'
import AddressElement from '@/components/stripe/addressElement.vue'
export default {
  name: 'checkoutElement',
  components: {
    AddressElement,
    StripeElementPayment
  },
  props: {
    products: {
      type: Array,
      default: function () {
        return []
      }
    }
  },
  data: () => ({
    isLoading: {
      paymentIntents: false,
      stripePayment: false
    },
    currentPanel: null,
    contactInformation: {
      firstName: null,
      lastName: null,
      email: null,
      phoneNumber: null
    },
    billingAddress: null,
    transaction: {
      uuid: null
    },
    stripe: {
      checkoutPayment: {
        ready: false,
        token: null,
        paymentIntentId: null,
        elementOptions: {
          clientSecret: null,
          appearance: {
            variables: {}
          }
        },
        elementStyle: {

        },
        confirmParams: {
          return_url: 'https://sea2sea-api.unixity.co/checkout/callback?host=https://sea2sea.unixity.co'
        }
      }
    }
  }),
  computed: {
    contactInformationFilled () {
      let filled = false

      if (this.contactInformation.firstName !== null && this.contactInformation.lastName !== null && this.contactInformation.email !== null && this.contactInformation.phoneNumber !== null) {
        if (this.$refs.contactInformationForm.validate()) {
          filled = true
        }
      }

      return filled
    },
    billingAddressFilled () {
      let filled = false

      if (this.billingAddress !== null) {
        filled = true
      }

      return filled
    }
  },
  methods: {
    doGeneratePaymentIntent () {
      this.isLoading.paymentIntents = true

      this.$services.checkout.doCreatePaymentIntent(this.products).then(data => {
        this.stripe.checkoutPayment.paymentIntentId = data.paymentIntentId
        this.stripe.checkoutPayment.elementOptions.clientSecret = data.clientSecret
        this.transaction = data
      }).catch(error => {
        this.$root.$emit('showError', error.message)
      }).finally(_ => {
        this.isLoading.paymentIntents = false
        this.stripe.checkoutPayment.ready = true

        setTimeout(() => {
          this.doOpenBasicInfo()
        }, 400)
      })
    },
    doCancelTransaction () {
      this.$services.checkout.doCancelTransaction(this.transaction.uuid).then(_ => {
        this.transaction = {
          uuid: null
        }
      }).catch(error => {
        console.log(error)
      })
    },
    doSaveAddress (address) {
      this.billingAddress = address
    },
    async doProcessTransaction () {
      const payment = {
        transaction: {
          uuid: this.transaction.uuid
        },
        billingAddress: {
          line1: this.billingAddress.line1,
          line2: this.billingAddress.line2,
          state: this.billingAddress.state,
          city: this.billingAddress.city,
          zipCode: this.billingAddress.postal_code,
          country: this.billingAddress.country
        },
        customer: {
          firstName: this.contactInformation.firstName,
          lastName: this.contactInformation.lastName,
          email: this.contactInformation.email,
          phoneNumber: this.contactInformation.phoneNumber,
          sessionUuid: this.$store.getters.doGetSession
        }
      }

      this.$services.checkout.doProcessTransaction(payment.customer, payment.billingAddress, payment.transaction).then(_ => {
        this.$refs.stripePayment.submit()
      }).catch(error => {
        this.$root.$emit(error.message)
      })
    },
    transactionLoading (state) {
      this.isLoading.stripePayment = state
    },
    doOpenBasicInfo () {
      this.currentPanel = 0
    },
    doOpenBilling () {
      this.currentPanel = 1
    },
    doOpenPayment () {
      this.currentPanel = 2
    },
    doGenerateTheme () {
      if (this.$vuetify.theme.isDark) {
        this.stripe.checkoutPayment.elementOptions.appearance.variables = {
          colorPrimary: '#0F75BC',
          colorBackground: '#1E1E1E',
          colorText: '#F9F9F9',
          colorDangerText: '#FF5252',
          colorWarningText: '#FF5252'
        }
      } else {
        this.stripe.checkoutPayment.elementOptions.appearance.variables = {
          colorPrimary: '#0F75BC',
          colorBackground: '#FFFFFF',
          colorText: '#212121',
          colorDangerText: '#FF5252',
          colorWarningText: '#FF5252'
        }
      }
    }
  },
  mounted () {
    this.doGenerateTheme()
    this.doGeneratePaymentIntent()
  }
}
</script>

<style scoped>
::v-deep .stripePaymentElement #stripe-payment-element-errors{
  color: #FF5252;
  font-size: 16px;
  font-weight: bold;
  margin-top: 12px;
}
</style>
