<template>
  <section>
    <v-row class="animationTransition">
      <v-col :xl="checkoutReady ? '4' : '9'" :lg="checkoutReady ? '4' : '8'" :md="checkoutReady ? '4' : '7'" sm="12" :class="checkoutReady ? 'hidden-sm-and-down animationTransition' : 'animationTransition'">
        <div v-for="(productGroup, indexA) in $store.state.cart.groups" :key="indexA" class="mb-6">
            <div v-if="productGroup.type === 'trip'">
              <trip-cart-item :uuid="productGroup.uuid" :tripId="productGroup.id" :tripDateId="productGroup.properties.tripDateId" :products="productGroup.products" :id="productGroup.uuid" :shrink="checkoutReady"></trip-cart-item>
            </div>
            <div v-if="productGroup.type === 'course'">
              <course-cart-item :uuid="productGroup.uuid" :courseId="productGroup.id" :products="productGroup.products" :id="productGroup.uuid" :shrink="checkoutReady"></course-cart-item>
            </div>
        </div>
        <div class="text-center my-16" v-if="productsByQuantity.length === 0">
          <div>
            Your cart is empty
          </div>
          <div>
            <v-btn class="mt-8" style="width: 200px;" color="primary" :to="{ name: 'courses' }">
              View Courses
            </v-btn>
          </div>
          <div>
            <v-btn class="mt-8" style="width: 200px;" color="primary" :to="{ name: 'trips' }">
              View Trips
            </v-btn>
          </div>
        </div>
      </v-col>
      <v-col :xl="checkoutReady ? '8' : '3'" :lg="checkoutReady ? '8' : '4'" :md="checkoutReady ? '8' : '5'" sm="12" :class="productsByQuantity.length === 0 ? 'hidden-sm-and-down animationTransition' : 'animationTransition'">
        <div class="sticky animationTransition">
          <v-card>
            <v-card-title v-if="!checkoutReady">
              My Cart
            </v-card-title>
            <v-card-title v-else>
              <div v-show="checkoutReady">
                <v-btn @click="doEditCart" color="primary">
                  <font-awesome-icon :icon="['fas', 'arrow-left']"/>
                  <span class="ml-2">Edit Cart</span>
                </v-btn>
              </div>
            </v-card-title>
            <v-card-text>
              <div class="text-center friendly-2 font-weight-bold mt-2 mb-8" v-if="checkoutReady">
                Checkout
              </div>
              <v-expand-transition>
                <div v-show="!checkoutReady">
                  <v-list>
                    <v-list-item v-for="(product, index) in productsByQuantity" :key="index">
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ product.title }}
                        </v-list-item-title>
                        <v-list-item-subtitle>
                          {{ product.quantity }} x ${{ product.cents / 100 }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </div>
              </v-expand-transition>
              <v-row>
                <v-col align="right">
                  <span class="friendly-1 font-weight-bold">
                    Total ${{ checkoutTotal }}
                  </span>
                </v-col>
              </v-row>
              <div class="mt-4" v-if="!checkoutReady">
                <v-btn @click="doCheckout" block color="primary" :disabled="productsByQuantity.length === 0">
                  Checkout
                </v-btn>
              </div>
              <div class="mt-4" v-else>
                <checkout-element :products="productsByQuantity" ref="checkoutElement"></checkout-element>
              </div>
            </v-card-text>
          </v-card>
        </div>
      </v-col>
    </v-row>
  </section>
</template>

<script>

import TripCartItem from '@/views/checkout/items/tripCartItem.vue'
import CourseCartItem from '@/views/checkout/items/courseCartItem.vue'
import CheckoutElement from '@/views/checkout/stripe/checkoutElement.vue'
export default {
  name: 'cart',
  components: { CheckoutElement, CourseCartItem, TripCartItem },
  computed: {
    productsByQuantity () {
      const stripeProductIds = []

      this.$store.state.cart.groups.forEach(group => {
        console.log(group)
        group.products.forEach(product => {
          const index = stripeProductIds.findIndex(({ stripeProductId }) => stripeProductId === product.stripeProductId)

          console.log(product)

          if (index === -1) {
            if (group.type === 'course') {
              stripeProductIds.push({
                id: product.id,
                title: `${product.organization.name} - ${product.name}`,
                name: product.name,
                stripeProductId: product.stripeProductId,
                cents: product.cents,
                quantity: 1,
                type: 'course'
              })
            }

            if (group.type === 'trip') {
              stripeProductIds.push({
                id: product.id,
                title: `${group.title} - ${product.name}`,
                name: product.name,
                stripeProductId: product.stripeProductId,
                cents: product.cents,
                quantity: 1,
                type: 'trip'
              })
            }
          } else {
            stripeProductIds[index].quantity = stripeProductIds[index].quantity + 1
          }
        })
      })

      return stripeProductIds
    },
    checkoutTotal () {
      let total = 0

      this.productsByQuantity.forEach(product => {
        total = total + (product.cents * product.quantity)
      })

      return total / 100
    }
  },
  data: () => ({
    checkoutReady: false
  }),
  methods: {
    doCheckout () {
      this.checkoutReady = true
    },
    doEditCart () {
      this.checkoutReady = false

      this.$refs.checkoutElement.doCancelTransaction()
    }
  },
  created () {
    this.doSetDocumentTitle('My Cart', true)
  }
}
</script>

<style scoped>
.sticky {
  position: sticky;
  top: 80px;
}
.itemImage {
  height: 300px;
}

.animationTransition {
  transition: flex 250ms, max-width 250ms, max-height 250ms;
}
</style>

<!--

We have something called product groups

When you add an item to the cart - it has a Product group.
If you add a course to your cart - You'll be taken to the checkout screen -
You can add or delete the course - easy.

When you add a trip - It's going to add all the productId's of the trip within the productGroup
 -->
