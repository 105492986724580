<template>
  <section>
    <div id="stripeBillingElement"></div>
  </section>
</template>

<script>
export default {
  name: 'addressElement',
  data: () => ({
    addressElement: null
  }),
  methods: {
    doCreateBillingAddressElement () {
      const appearance = {}

      if (this.$vuetify.theme.isDark) {
        appearance.variables = {
          colorPrimary: '#0F75BC',
          colorBackground: '#1E1E1E',
          colorText: '#F9F9F9',
          colorDangerText: '#FF5252'
        }
      } else {
        appearance.variables = {
          colorPrimary: '#0F75BC',
          colorBackground: '#FFFFFF',
          colorText: '#212121',
          colorDangerText: '#FF5252'
        }
      }

      this.addressElement = this.$stripe.elements({ undefined, appearance }).create('address', {
        mode: 'billing',
        autocomplete: {
          mode: 'disabled'
        },
        fields: {
          phone: 'never'
        },
        allowedCountries: ['US']
      })

      this.addressElement.mount('#stripeBillingElement')

      this.addressElement.on('change', (event) => {
        if (event.complete) {
          const address = event.value.address
          this.$emit('complete', address)
        }
      })
    }
  },
  mounted () {
    this.doCreateBillingAddressElement()
  }
}
</script>

<style scoped>

</style>
