<template>
  <section>
    <div v-if="!isLoading">
      <v-card class="rounded-lg">
        <v-card-text class="pa-0">
          <v-row no-gutters>
            <v-col sm="4" class="py-0" v-if="!shrink">
              <v-carousel hide-delimiters :cycle="true" :show-arrows="false" style="height: 100%; width: 100%;" class="hidden-xs-only">
                <v-carousel-item v-for="(image, index) in trip.images" :key="index" style="height: 100%; width: 100%;">
                  <v-img :src="image.url" style="height: 100%; width: 100%;" class="rounded-bl-lg rounded-tl-lg"></v-img>
                </v-carousel-item>
              </v-carousel>
              <v-carousel hide-delimiters :cycle="true" :show-arrows="false" style="height: 80px; width: 100%;" class="hidden-sm-and-up">
                <v-carousel-item v-for="(image, index) in trip.images" :key="index" style="height: 100%; width: 100%;">
                  <v-img :src="image.url" style="height: 100%; width: 100%;" class="rounded-tl-lg rounded-tr-lg"></v-img>
                </v-carousel-item>
              </v-carousel>
            </v-col>
            <v-col sm="12" class="py-0" v-else>
              <v-carousel hide-delimiters :cycle="true" :show-arrows="false" style="height: 80px; width: 100%;">
                <v-carousel-item v-for="(image, index) in trip.images" :key="index" style="height: 100%; width: 100%;">
                  <v-img :src="image.url" style="height: 100%; width: 100%;" class="rounded-tl-lg rounded-tr-lg"></v-img>
                </v-carousel-item>
              </v-carousel>
            </v-col>
            <v-col class="pa-4" style="position: relative;">
              <div :class="shrink ? 'friendly-2' : 'friendly-3'">
                {{ trip.name }}
              </div>
              <div class="friendly-1 mt-2" v-if="!shrink">
                {{ $moment(selectedTripDate.start).format('LL') }} to {{ $moment(selectedTripDate.end).format('LL') }}
              </div>
              <div class="friendly-1 mt-2" v-if="shrink">
                {{ $moment(selectedTripDate.start).format('ll') }} to {{ $moment(selectedTripDate.end).format('ll') }}
              </div>
              <div class="mt-2" v-if="!shrink">
                <p v-snip="{ lines: 4, mode: 'js', midWord: false }">
                  {{ trip.subtitle }}
                </p>
              </div>
              <div>
                <div v-if="selectedTripDate.products.length >= 1">
                  <v-expansion-panels v-if="!shrink">
                    <v-expansion-panel>
                      <v-expansion-panel-header disable-icon-rotate>
                        Trip Options
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <div v-for="(productKey, indexB) in Object.keys(doCategorizeProducts(selectedTripDate.products))" :key="indexB" class="my-2 pa-4">
                          <div class="subtitle-1 font-weight-bold">{{ productKey }}</div>
                          <v-divider></v-divider>
                          <div v-for="(product, indexC) in doCategorizeProducts(selectedTripDate.products)[productKey]" :key="indexC">
                            <div class="inlineRow">
                              <div>
                                <div v-if="product.isRequired">
                                  <v-checkbox off-icon="fa-regular fa-square" :value="true" v-model="product.isRequired" disabled></v-checkbox>
                                </div>
                                <div v-else>
                                  <v-checkbox v-model="cartProducts" off-icon="fa-regular fa-square" :value="product.id" :disabled="product.isRequired"></v-checkbox>
                                </div>
                              </div>

                              <div style="min-width: 140px;">
                                <v-list>
                                  <v-list-item>
                                    <v-list-item-content>
                                      <v-list-item-title>
                                        <div>
                                          {{ product.name }}
                                        </div>
                                        <div class="caption red--text">
                                          {{ product.isRequired ? 'Required' : '' }}
                                        </div>
                                      </v-list-item-title>
                                    </v-list-item-content>
                                  </v-list-item>
                                </v-list>
                              </div>

                              <div>
                                <v-list>
                                  <v-list-item>
                                    <v-list-item-content>
                                      <v-list-item-title>
                                        <div class="title">
                                          ${{ product.cents / 100 }}
                                        </div>
                                      </v-list-item-title>
                                    </v-list-item-content>
                                  </v-list-item>
                                </v-list>
                              </div>
                            </div>

                            <div class="productDescription">
                              {{ product.description }}
                            </div>
                          </div>
                        </div>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </div>
                <div class="pa-4">
                  <v-row v-if="!shrink">
                    <v-col align="right">
                      <span class="friendly-2 font-weight-black">Trip Total ${{ grandTotal }}</span>
                    </v-col>
                  </v-row>
                  <v-row v-if="shrink">
                    <v-col align="right">
                      <span class="friendly-2 font-weight-black">Total ${{ grandTotal }}</span>
                    </v-col>
                  </v-row>
                  <v-row v-if="!shrink" no-gutters class="mt-4">
                    <v-col sm="2" class="mr-2">
                      <v-btn block @click="doRemove" color="error">
                        <font-awesome-icon :icon="['fas', 'trash']"/>
                      </v-btn>
                    </v-col>
                    <v-col>
                      <v-btn block @click="doDuplicate" color="primary">Add Another Person</v-btn>
                    </v-col>
                  </v-row>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </div>
  </section>
</template>

<script>
export default {
  name: 'tripCartItem',
  props: {
    tripId: {
      type: Number,
      default: 0
    },
    tripDateId: {
      type: Number,
      default: 0
    },
    products: {
      type: Array,
      default: () => {
        return []
      }
    },
    uuid: {
      type: String,
      default: String()
    },
    shrink: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    isLoading: true,
    selectedTripDate: null,
    trip: {
      dates: []
    }
  }),
  computed: {
    cartProducts: {
      // getter
      get () {
        return this.products.map(({ id }) => {
          return id
        })
      },
      // setter
      set (setProductIds) {
        const newProducts = this.selectedTripDate.products.filter(({ id }) => setProductIds.includes(id))

        this.$store.dispatch('doSetProductsInProductGroup', {
          uuid: this.uuid,
          products: newProducts
        })
      }
    },
    grandTotal () {
      let total = 0

      this.products.forEach(product => {
        total = total + product.cents
      })

      return (total / 100)
    }
  },
  methods: {
    doLoad () {
      this.$services.trip.doGetById(this.tripId).then(trip => {
        if (trip === null) {
          this.doRemove()
          this.$root.$emit('showError', 'The trip added to your cart is no longer available.')
        } else {
          this.trip = trip

          if (trip.dates.length >= 1) {
            this.selectedTripDate = trip.dates.find(({ id }) => id === this.tripDateId)
          }
        }
      }).catch(error => {
        this.doRemove()
        this.$root.$emit('showError', error.message)
      }).finally(_ => {
        this.$forceUpdate()
        this.isLoading = false
      })
    },
    doCategorizeProducts (products) {
      const categorizedProducts = {}

      products.forEach(product => {
        if (product.category.name in categorizedProducts) {
          categorizedProducts[product.category.name].push(product)
        } else {
          categorizedProducts[product.category.name] = [product]
        }
      })

      return categorizedProducts
    },
    doDuplicate () {
      const productGroup = {
        id: this.tripId,
        uuid: this.$uuid.v4(),
        title: this.trip.name,
        description: this.trip.subtitle,
        images: this.trip.images,
        type: 'trip',
        products: this.products,
        properties: {
          tripDateId: this.tripDateId
        }
      }

      this.$store.dispatch('doAddProductGroup', productGroup)
    },
    doRemove () {
      this.$store.dispatch('doRemoveProductGroup', this.uuid)
    }
  },
  mounted () {
    this.doLoad()
  }
}
</script>

<style scoped>

.inlineRow {
  display: flex;
  flex-direction: row;
}

.productDescription {
  margin-top: -20px;
  margin-bottom: 10px;
}

::v-deep .v-carousel__item {
  height: 100% !important;
  width: 100% !important;
}
</style>
